<template>
  <div>
    <NavbarStyleNine />
    <MainBanner />
    <Features />
    <Services />
    <About />
    <OurDoctors />
    <VisitApartment />
    <Blog />
    <GetStarted />
    <FooterStyleFive class="bg-f9f9f9" />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/Navbar";
import MainBanner from "../Medical/MainBanner";
import Features from "../Medical/Features";
import Services from "../Medical/Services";
import About from "../Medical/About";
import OurDoctors from "../Medical/OurDoctors";
import VisitApartment from "../Medical/VisitApartment";
import Blog from "../Medical/Blog";
import GetStarted from "../Medical/GetStarted";
import FooterStyleFive from "../Layouts/Footer";

export default {
  name: "MedicalPage",
  components: {
    NavbarStyleNine,
    MainBanner,
    Features,
    Services,
    About,
    OurDoctors,
    VisitApartment,
    Blog,
    GetStarted,
    FooterStyleFive,
  },
};
</script>
