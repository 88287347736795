<template>
  <div>
    <Navbar />
    <PageTitle
      subTitle="Portfolio"
      heding="We've done lot's of work, Let's check some"
    />
    <Portfolio />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import Portfolio from "../PortfolioTwo/Portfolio";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "PortfolioTwoPage",
  components: {
    Navbar,
    PageTitle,
    Portfolio,
    FooterStyleTwo,
  },
};
</script>
