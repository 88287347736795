<template>
  <div id="app">
    <div class="team-container">
      <br />
      <h1 class="title">Student Reseach and Publications</h1>
    </div>
    <div class="table-controls">
      <div>
        Show
        <select v-model="papersPerPage">
          <option>5</option>
          <option>10</option>
        </select>
        entries
      </div>
      <div class="search-bar">
        <input v-model="searchTerm" placeholder="Search papers..." />
      </div>
    </div>

    <table class="papers-table">
      <thead>
        <tr>
          <th style="width: 15%; text-align: center">Author</th>
          <th style="text-align: center">Paper Title</th>
          <th style="width: 25%; text-align: center">Publisher/Conference</th>
          <th style="width: 5%; text-align: center">
            Year of Publications/Presentation
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="paper in displayedPapers" :key="paper.author">
          <td>{{ paper.author }}</td>
          <td>{{ paper.Title }}</td>
          <td>{{ paper.publisher }}</td>
          <td style="width: 5%; text-align: center">{{ paper.year }}</td>
        </tr>
      </tbody>
    </table>

    <div class="pagination-container">
      <div>
        Showing {{ startIndex }} to {{ endIndex }} of
        {{ filteredPapers.length }} entries
      </div>
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage <= 1">Previous</button>
        <span>{{ currentPage }}</span>
        <button @click="nextPage" :disabled="currentPage >= totalPages">
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import papers from "../../assets/json/publications";

export default {
  data() {
    return {
      searchTerm: "",
      papers,
      papersPerPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    filteredPapers() {
      if (!this.searchTerm) return this.papers; // If search term is empty, return all papers
      return this.papers.filter(
        (paper) =>
          paper.author.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          paper.Title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          paper.year.toString().includes(this.searchTerm) // Check for author, title, and year
      );
    },
    displayedPapers() {
      const start = (this.currentPage - 1) * this.papersPerPage;
      const end = start + this.papersPerPage;
      return this.filteredPapers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredPapers.length / this.papersPerPage);
    },
    startIndex() {
      return (this.currentPage - 1) * this.papersPerPage + 1;
    },
    endIndex() {
      const end = this.startIndex + this.displayedPapers.length - 1;
      return end > this.filteredPapers.length
        ? this.filteredPapers.length
        : end;
    },
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
  watch: {
    // Watcher for the searchTerm
    searchTerm() {
      this.currentPage = 1; // Reset to first page when search term changes
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f8fbff;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.papers-table {
  width: 98%; /* Set the table width to 75% */
  margin: 0 auto; /* Center the table horizontally */
  border-collapse: collapse;
  margin-bottom: 20px;
  border-radius: 10px; /* Add rounded corners */
  overflow: hidden; /* Ensure content doesn't overflow the rounded corners */
}

.papers-table thead tr {
  background-color: #f5f5f5;
}

.papers-table th,
.papers-table td {
  padding: 12px 20px;
  border: 1px solid #e0e0e0;
  text-align: left;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
  align-items: center;
}

button {
  padding: 5px 15px;
  margin: 0 10px;
  border: none;
  background-color: #4caf50;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.search-bar {
  padding: 10px 20px; /* Add padding to the search bar for better appearance */
}
.team-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff; /* Assuming a white background. Adjust as needed. */
  height: 20vh;
}

.subtitle {
  font-size: 1.5rem; /* Adjust based on your design. */
  color: #ff69b4; /* Pink color. Adjust as needed. */
  margin-bottom: 1rem; /* Space between the subtitle and the title. Adjust as needed. */
}

.title {
  font-size: 2.5rem; /* Adjust based on your design. */
  font-weight: bold;
  color: #000000; /* Assuming black text color. Adjust as needed. */
}
</style>
