<template>
  <div>
    <Navbar />
    <PageTitle
      subTitle="Our Features"
      heding="Most probably included best features ever"
    />
    <Services />
    <ReceivePayments />
    <BigDataValue />
    <OurGoal />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import Services from "../Features/Services";
import ReceivePayments from "../Features/ReceivePayments";
import BigDataValue from "../Features/BigDataValue";
import OurGoal from "../Features/OurGoal";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "FeaturesPage",
  components: {
    Navbar,
    PageTitle,
    Services,
    ReceivePayments,
    BigDataValue,
    OurGoal,
    FooterStyleTwo,
  },
};
</script>
