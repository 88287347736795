<template>
  <div>
    <NavbarStyleSeven />

    <Courses />

    <FooterStyleSeven />
  </div>
</template>

<script>
import NavbarStyleSeven from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import Courses from "../Courses/Courses";
import AppDownload from "../Courses/AppDownload";
import FooterStyleSeven from "../Layouts/Footer";

export default {
  name: "CoursesPage",
  components: {
    NavbarStyleSeven,
    PageTitle,
    Courses,
    AppDownload,
    FooterStyleSeven,
  },
};
</script>
