<template>
  <div>
    <Navbar />
    <PageTitle subTitle="Shop" heding="Products Lists" />
    <Products />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import Products from "../Shop/Products";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "ProductsPage",
  components: {
    Navbar,
    PageTitle,
    Products,
    FooterStyleTwo,
  },
};
</script>
