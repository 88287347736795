<template>
  <div>
    <NavbarStyleFive />
    <MainBanner />
    <Features />
    <OurGoal />
    <WhatWeOffer />
    <ResidenceArea />
    <Testimonials />
    <Gallery />
    <Blog />
    <VisitApartment />
    <Partner class="ptb-100 bg-f9f9f9 br-bottom-100" />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleFive from "../Layouts/Navbar";
import MainBanner from "../Property/MainBanner";
import Features from "../Property/Features";
import OurGoal from "../Property/OurGoal";
import WhatWeOffer from "../Property/WhatWeOffer";
import ResidenceArea from "../Property/ResidenceArea";
import Testimonials from "../Property/Testimonials";
import Gallery from "../Property/Gallery";
import Blog from "../Property/Blog";
import VisitApartment from "../Property/VisitApartment";
import Partner from "../Common/Partner";
import FooterStyleFive from "../Layouts/Footer";

export default {
  name: "PropertyPage",
  components: {
    NavbarStyleFive,
    MainBanner,
    Features,
    OurGoal,
    WhatWeOffer,
    ResidenceArea,
    Testimonials,
    Gallery,
    Blog,
    VisitApartment,
    Partner,
    FooterStyleFive,
  },
};
</script>
