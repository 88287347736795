<template>
  <div>
    <Navbar />
    <PageTitle subTitle="Services Details" heding="Android Apps Development" />
    <ServicesDetails />
    <GetStarted />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import ServicesDetails from "../ServicesDetails/ServicesDetails";
import GetStarted from "../ServicesDetails/GetStarted";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "ServicesDetailsPage",
  components: {
    Navbar,
    PageTitle,
    ServicesDetails,
    GetStarted,
    FooterStyleTwo,
  },
};
</script>
