<template>
  <div class="template-footer-two pt-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="single-footer-widget">
            <router-link to="/" class="logo">
              <img src="../../assets/images/logo.png" alt="logo" />
            </router-link>

            <ul class="footer-contact-info">
              <li>
                <i class="ph-phone-call"></i>
                <a href="tel:+1-485-456-0102">(+632) 8733-9117</a>
              </li>
              <li>
                <i class="ph-envelope-simple"></i>
                <a>mlagradprogram@tip.edu.ph</a>
              </li>
              <li>
                <i class="ph-map-pin"></i>
                363 P. Casal St., Quiapo Manila <br />1338 Arlegui St., Quiapo,
                Manila
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-footer-widget pl-3"></div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-footer-widget"></div>
        </div>
      </div>
    </div>

    <div class="copyright-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-7">
            <p>
              Copyright @{{ currentYear }} T.I.P. Graduate Programs. All Rights
              Reserved.
            </p>
          </div>
          <div class="col-lg-6 col-md-5">
            <ul class="social-links">
              <li>
                <a
                  href="https://www.facebook.com/groups/179828462581721"
                  target="_blank"
                >
                  <i class="flaticon-facebook-app-symbol"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/TIP1962official" target="_blank">
                  <i class="flaticon-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCuV9LML0WXzdI0OYgmiPk1w"
                  target="_blank"
                >
                  <i class="flaticon-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/tip1962official/"
                  target="_blank"
                >
                  <i class="flaticon-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterStyleTwo",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
