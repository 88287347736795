<template>
  <div>
    <Navbar />
    <PageTitle
      subTitle="Blog Details"
      heding="Top 10 important tips on IT services & design"
    />
    <BlogDetailsTwo />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import BlogDetailsTwo from "../BlogDetailsTwo/BlogDetailsTwo";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "BlogDetailsTwoPage",
  components: {
    Navbar,
    PageTitle,
    BlogDetailsTwo,
    FooterStyleTwo,
  },
};
</script>
