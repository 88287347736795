<template>
  <div>
    <NavbarStyleSix />
    <MainBanner />
    <Partner />
    <Features />
    <DataValue />
    <WhyChooseUs />
    <WorkingProcess />
    <DataResources />
    <Testimonials />
    <Faq />
    <Blog />
    <FreeTrial />
    <FooterStyleSix />
  </div>
</template>

<script>
import NavbarStyleSix from "../Layouts/Navbar";
import MainBanner from "../BigData/MainBanner";
import Partner from "../BigData/Partner";
import Features from "../BigData/Features";
import DataValue from "../BigData/DataValue";
import WhyChooseUs from "../BigData/WhyChooseUs";
import WorkingProcess from "../BigData/WorkingProcess";
import DataResources from "../BigData/DataResources";
import Testimonials from "../BigData/Testimonials";
import Faq from "../BigData/Faq";
import Blog from "../BigData/Blog";
import FreeTrial from "../BigData/FreeTrial";
import FooterStyleSix from "../Layouts/Footer";

export default {
  name: "BigDataPage",
  components: {
    NavbarStyleSix,
    MainBanner,
    Partner,
    Features,
    DataValue,
    WhyChooseUs,
    WorkingProcess,
    DataResources,
    Testimonials,
    Faq,
    Blog,
    FreeTrial,
    FooterStyleSix,
  },
};
</script>
