<template>
  <div :class="['navbar-area p-relative', { 'is-sticky': isSticky }]">
    <div class="vumy-nav">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <router-link class="navbar-brand" to="/">
            <img src="../../assets/images/logo.png" alt="logo" />
          </router-link>

          <div
            class="navbar-toggler"
            @click="show = !show"
            :aria-pressed="show ? 'true' : 'false'"
            v-bind:class="{ show: button_show_state }"
            v-on:click="button_show_state = !button_show_state"
          >
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </div>

          <div class="collapse navbar-collapse" :class="{ show: show }">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link to="/" class="nav-link">Home</router-link>
              </li>
              <li class="nav-item megamenu">
                <router-link to="/programs" class="nav-link"
                  >Program Offerings</router-link
                >
              </li>
              <li class="nav-item megamenu">
                <router-link to="/team" class="nav-link"
                  >Faculty and Staff</router-link
                >
              </li>

              <li class="nav-item megamenu">
                <router-link to="/researches" class="nav-link"
                  >Researches</router-link
                >
              </li>

              <li class="nav-item megamenu">
                <router-link to="/news-announcements" class="nav-link"
                  >News and Announcements</router-link
                >
              </li>

              <li class="nav-item">
                <router-link to="/contact" class="nav-link"
                  >Contact Us</router-link
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarStyleSeven",
  data() {
    return {
      isSticky: false,
      show: false,
      button_show_state: false,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
};
</script>
