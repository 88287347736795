<template>
  <div>
    <Navbar />
    <PageTitle
      subTitle="Latest News"
      heding="Our latest articles & resources"
    />
    <BlogSpecial />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import BlogSpecial from "../BlogSpecial/BlogSpecial";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "BlogSpecialPage",
  components: {
    Navbar,
    PageTitle,
    BlogSpecial,
    FooterStyleTwo,
  },
};
</script>
