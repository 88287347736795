<template>
  <div class="blog-area ptb-100">
    <div class="container">
      <div class="row justify-content-center">
        <!-- Use v-for to loop through documents array -->
        <div
          v-for="document in documents"
          :key="document.id"
          class="col-xl-4 col-lg-6 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div class="single-blog-post">
            <div class="image">
              <router-link
                :to="'/announcement-details/' + document.id"
                class="d-block"
              >
                <img
                  :src="document.imageURL"
                  alt="blog-details"
                  @error="handleImageError"
                  class="image-container"
                />
              </router-link>
            </div>
            <div class="content">
              <ul class="meta">
                <li>
                  <i class="ph-tag"></i>
                  {{ document.category }}
                  <!-- Dynamic category -->
                </li>
                <li>
                  <i class="ph-calendar-check"></i>
                  {{ document.date }}
                  <!-- Dynamic date -->
                </li>
              </ul>
              <h3>
                <router-link :to="'/announcement-details/' + document.id">
                  {{ document.title }}
                  <!-- Dynamic title -->
                </router-link>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../../firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { ref, onMounted } from "vue";

export default {
  name: "NewsGrid",
  data() {
    return {
      documents: [],
    };
  },
  methods: {
    async fetchData() {
      try {
        // Create a Firestore query to order documents by the 'date' field in descending order (latest first)
        const q = query(collection(db, "news"), orderBy("date", "desc"));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((item) => {
          this.documents.push({ id: item.id, ...item.data() });
        });
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    handleImageError(event) {
      event.target.src = require("@/assets/images/error/default.jpg");
      event.target.alt = "Fallback Image";
    },
  },
  mounted() {
    this.fetchData(); // Call the method to fetch data from Firebase
  },
};
</script>
<style>
.image-container {
  max-width: 100%; /* Set the maximum width to prevent image overflow */

  object-fit: cover; /* Maintain aspect ratio and cover the container */
}
</style>
