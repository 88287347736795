<template>
    <div class="overview-area ptb-100">
        <div class="container">
            <div class="overview-box">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-image">
                        <img src="../../assets/images/overview/overview1.png" data-aos="fade-up" alt="overview">
                    </div>
                    <div class="col-lg-6 col-md-12 overview-content">
                        <span class="sub-title">Services We Offer</span>
                        <h2>We are here, to help your startup business</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Cloud Databases
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Website Hosting
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Remote Desktop
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    File Backup
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="overview-box">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-content">
                        <span class="sub-title">Our Services</span>
                        <h2>Best IT & technology  service in your area</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Design & Development
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Android Apps Development
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Laravel Web Development
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    React Web Development
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-12 overview-image">
                        <img src="../../assets/images/overview/overview2.png" data-aos="fade-up" alt="overview">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>