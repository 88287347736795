<template>
  <div>
    <Navbar />
    <PageTitle
      subTitle="Latest News"
      heding="Student latest Articles and Achievements"
    />
    <NewsPage />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";

import FooterStyleTwo from "../Layouts/Footer";
import NewsPage from "../news/NewsGrid.vue";

export default {
  name: "BlogGridPage",
  components: {
    Navbar,
    PageTitle,
    NewsPage,
    FooterStyleTwo,
  },
};
</script>
