<template>
  <div>
    <Navbar />
    <div class="page-title-area">
      <div class="container">
        <div class="page-title-content">
          <h1>Search result for: "Design"</h1>
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/blog-grid">Blog</router-link></li>
            <li>Technology</li>
          </ul>
        </div>
      </div>
    </div>
    <BlogSearch />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import BlogSearch from "../BlogSearch/BlogSearch";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "BlogSearchPage",
  components: {
    Navbar,
    BlogSearch,
    FooterStyleTwo,
  },
};
</script>
