<template>
  <div>
    <NavbarStyleFour />
    <MainBanner />
    <Features />
    <OurGoal />
    <ApplyProcess />
    <ProtectYou />
    <Testimonials />
    <WhatWeOffer />
    <Faq />
    <JoinOurCommunity />
    <FooterStyleFour />
  </div>
</template>

<script>
import NavbarStyleFour from "../Layouts/Navbar";
import MainBanner from "../Insurance/MainBanner";
import Features from "../Insurance/Features";
import OurGoal from "../Insurance/OurGoal";
import ApplyProcess from "../Insurance/ApplyProcess";
import ProtectYou from "../Insurance/ProtectYou";
import Testimonials from "../Insurance/Testimonials";
import WhatWeOffer from "../Insurance/WhatWeOffer";
import Faq from "../Common/Faq";
import JoinOurCommunity from "../Insurance/JoinOurCommunity";
import FooterStyleFour from "../Layouts/Footer";

export default {
  name: "InsurancePage",
  components: {
    NavbarStyleFour,
    MainBanner,
    Features,
    OurGoal,
    ApplyProcess,
    ProtectYou,
    Testimonials,
    WhatWeOffer,
    Faq,
    JoinOurCommunity,
    FooterStyleFour,
  },
};
</script>
