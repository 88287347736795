<template>
  <div>
    <Navbar />
    <PageTitle subTitle="Authentication" heding="Forgot Your Password?" />
    <ForgotPassword />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import ForgotPassword from "../Authentication/ForgotPassword";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "ForgotPasswordPage",
  components: {
    Navbar,
    PageTitle,
    ForgotPassword,
    FooterStyleTwo,
  },
};
</script>
