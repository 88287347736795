<template>
  <div>
    <NavbarStyleFive />
    <ResearchList />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleFive from "../Layouts/Navbar";
import ResearchList from "../../components/Researches/Researches.vue";
import Partner from "../BlogRightSidebar/Partner";
import FooterStyleFive from "../Layouts/Footer";

export default {
  name: "BlogRightSidebarPage",
  components: {
    NavbarStyleFive,
    ResearchList,
    Partner,
    FooterStyleFive,
  },
};
</script>
