<template>
    <div>
        <Navbar />
        <MainBanner />
        <HelpDesk />
        <Services />
        <WhatWeDo />
        <Faq />
        <Testimonials />
        <Team />
        <Blog />
        <Subscribe />
        <Partner />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../ITStartup/MainBanner'
import HelpDesk from '../ITStartup/HelpDesk'
import Services from '../ITStartup/Services'
import WhatWeDo from '../ITStartup/WhatWeDo'
import Faq from '../ITStartup/Faq'
import Testimonials from '../Common/Testimonials'
import Team from '../ITStartup/Team'
import Blog from '../Common/Blog'
import Subscribe from '../ITStartup/Subscribe'
import Partner from '../ITStartup/Partner'
import Footer from '../Layouts/Footer'

export default {
    name: 'ITStartupPage',
    components: {
        Navbar,
        MainBanner,
        HelpDesk,
        Services,
        WhatWeDo,
        Faq,
        Testimonials,
        Team,
        Blog,
        Subscribe,
        Partner,
        Footer,
    }
}
</script>