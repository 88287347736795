<template>
  <div
    class="distance-learning-banner-area movearea"
    @mousemove="onMousemove"
    :style="{ backgroundColor: `hsl(${x}, 50%, 95%)` }"
  >
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-xl-4 col-lg-12 col-md-12">
          <div class="distance-learning-banner-content">
            <span class="sub-title" data-aos="fade-up" data-aos-duration="1200"
              >Technological Institute of the Philippines - Manila</span
            >
            <h1
              class="nunito-font"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              Welcome to Graduate Programs!
            </h1>
            <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
              Explore the world of advanced learning at T.I.P. Graduate
              Programs! Our diverse offerings are designed to cater to
              professionals and lifelong learners committed to expanding their
              horizons. We prioritize a learning experience that is both dynamic
              and profoundly impactful, nurturing graduates who are ready to
              make significant contributions in their respective fields.
            </p>
            <div
              class="btn-box"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="300"
            >
              <a
                href="https://admission.tip.edu.ph/web/"
                class="btn-style-one yellow-color"
                target="_blank"
                rel="noopener noreferrer"
              >
                Enroll Now
                <i class="ph-caret-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12">
          <div class="distance-learning-banner-image">
            <img
              src="../../assets/images/banner/banner7.png"
              alt="banner-image"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="shape16">
      <img src="../../assets/images/shape/shape16.png" alt="shape" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainBanner',
  data() {
    return {
      x: 0,
    };
  },
  methods: {
    onMousemove(e) {
      this.x = e.clientX;
    },
  },
};
</script>
