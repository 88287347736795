<template>
  <div>
    <NavbarStyleSeven />
    <!-- Dynamically render the program-specific details component -->
    <component :is="programComponent" />
    <FooterStyleSeven />
  </div>
</template>

<script>
import NavbarStyleSeven from "../Layouts/Navbar";
import FooterStyleSeven from "../Layouts/Footer";

export default {
  name: "ProgramDetailsPage",
  components: {
    NavbarStyleSeven,
    FooterStyleSeven,
  },
  data() {
    return {
      programComponent: null, // To hold the dynamically imported program component
    };
  },
  created() {
    this.loadComponent();
  },
  watch: {
    "$route.params.id": "loadComponent", // Watch for changes in the route parameter
  },
  methods: {
    loadComponent() {
      const programId = this.$route.params.id;
      // Dynamically import the correct program details component based on the route parameter 'id'
      import(`@/components/Programs/${programId}.vue`)
        .then((component) => {
          this.programComponent = component.default; // Set the imported component to be used in the template
        })
        .catch((error) => {
          console.error("Error loading component:", error);
          // Optionally, you can handle a fallback to a NotFound component
          this.programComponent = () =>
            import("@/components/Programs/NotFound.vue");
        });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
