<template>
    <div class="pricing-area with-top-border pt-100 pb-75">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-4 col-lg-12 col-md-12" data-aos="fade-up" data-aos-duration="1200">
                    <div class="section-title pricing-section-title">
                        <span class="sub-title">Pricing Table</span>
                        <h2>No Hidden Charge Applied, Choose Your Plan</h2>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-12 col-md-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                            <div class="single-pricing-box red-light-color">
                                <h3>Small Team</h3>
                                <p>Powerful & awesome elements</p>
                                <div class="price">
                                    $49<span>/month</span>
                                </div>
                                <router-link to="/contact" class="btn-style-one red-light-color">
                                    Purchage Plan 
                                    <i class="ph-caret-right"></i>
                                </router-link>
                                <ul class="features-list">
                                    <li>
                                        <i class="flaticon-draw-check-mark"></i>
                                        Up to 10 Website
                                    </li>
                                    <li>
                                        <i class="flaticon-draw-check-mark"></i>
                                        Lifetime Free Support
                                    </li>
                                    <li>
                                        <i class="flaticon-draw-check-mark"></i>
                                        10 GB Dedicated Hosting Free
                                    </li>
                                    <li>
                                        <i class="flaticon-draw-check-mark"></i>
                                        24/7 Support
                                    </li>
                                    <li>
                                        <i class="flaticon-draw-check-mark"></i>
                                        SEO Optimized
                                    </li>
                                    <li class="close">
                                        <i class="flaticon-cancel"></i>
                                        Live Support
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <div class="single-pricing-box red-light-color">
                                <h3>Business Plan</h3>
                                <p>Powerful & awesome elements</p>
                                <div class="price">
                                    $69<span>/month</span>
                                </div>
                                <router-link to="/contact" class="btn-style-one red-light-color">
                                    Purchage Plan 
                                    <i class="ph-caret-right"></i>
                                </router-link>
                                <ul class="features-list">
                                    <li>
                                        <i class="flaticon-draw-check-mark"></i>
                                        Up to 80 Website
                                    </li>
                                    <li>
                                        <i class="flaticon-draw-check-mark"></i>
                                        Lifetime Free Support
                                    </li>
                                    <li>
                                        <i class="flaticon-draw-check-mark"></i>
                                        80 GB Dedicated Hosting Free
                                    </li>
                                    <li>
                                        <i class="flaticon-draw-check-mark"></i>
                                        24/7 Support
                                    </li>
                                    <li>
                                        <i class="flaticon-draw-check-mark"></i>
                                        SEO Optimized
                                    </li>
                                    <li>
                                        <i class="flaticon-draw-check-mark"></i>
                                        Live Support
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pricing'
}
</script>