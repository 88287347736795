<template>
    <div class="request-a-visit-area bg2 ptb-100">
        <div class="container">
            <div class="request-a-visit-form" data-aos="fade-up" data-aos-duration="1200">
                <span class="sub-title">Visit Apartment</span>
                <h2>Make an appointment</h2>
                <form @submit.prevent>
                    <div class="form-group">
                        <input type="text" placeholder="Full Name">
                    </div>
                    <div class="form-group">
                        <input type="email" placeholder="Enter you emaill address">
                    </div>
                    <div class="form-group">
                        <input type="text" placeholder="MM / DD / YY">
                    </div>
                    <div class="form-group">
                        <select class="form-select">
                            <option selected>Select Department</option>
                            <option value="1">Cardiology</option>
                            <option value="2">Neurosurgery</option>
                            <option value="3">Dental</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <textarea cols="30" rows="4" placeholder="Your Message"></textarea>
                    </div>
                    <button type="submit" class="btn-style-one red-light-color">Submit Now <i class="ph-caret-right"></i></button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VisitApartment'
}
</script>