<template>
  <div class="team-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="team-content" data-aos="fade-up" data-aos-duration="1200">
            <span class="sub-title">team</span>
            <h2>Join our esteemed team and inspire the next generation.</h2>
            <p>
              Unlock the future of education with T.I.P.'s graduate programs. We
              invite passionate educators to join our esteemed team and shape
              the next generation of leaders. Seize this opportunity to inspire
              and be a beacon of knowledge as a professor at T.I.P.
            </p>
            <router-link to="/contact" class="btn-style-one red-light-color">
              Contact Us
              <i class="ph-caret-right"></i>
            </router-link>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="team-member-list">
            <ul>
              <li data-aos="zoom-in" data-aos-duration="1200">
                <img
                  src="../../assets/images/team/team1.png"
                  alt="member-image"
                />
              </li>
              <li
                data-aos="fade-down"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <img
                  src="../../assets/images/team/team2.png"
                  alt="member-image"
                />
              </li>
              <li
                data-aos="fade-down"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <img
                  src="../../assets/images/team/team3.png"
                  alt="member-image"
                />
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <img
                  src="../../assets/images/team/team4.png"
                  alt="member-image"
                />
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="400"
              >
                <img
                  src="../../assets/images/team/team5.png"
                  alt="member-image"
                />
              </li>
              <li
                data-aos="fade-down"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img
                  src="../../assets/images/team/team6.png"
                  alt="member-image"
                />
              </li>
            </ul>
            <img
              src="../../assets/images/shape/bg-shape1.jpg"
              alt="bg-image"
              class="bg-image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="team-area pb-75">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-3 col-md-6 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div class="single-team-member bg1">
            <img src="../../assets/images/team/team7.jpg" alt="member-image" />
            <div class="content">
              <h3>Dr. Enrique D. Festijo</h3>
              <ul class="social">
                <li>
                  <a
                    href="https://scholar.google.com/citations?user=C4L2AokAAAAJ&hl=en&oi=ao"
                    target="_blank"
                  >
                    <i class="flaticon-google"></i>
                  </a>
                </li>

                <li>
                  <a
                    href="https://ph.linkedin.com/in/enriquefestijo"
                    target="_blank"
                  >
                    <i class="flaticon-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div class="single-team-member bg2">
            <img src="../../assets/images/team/team8.jpg" alt="member-image" />
            <div class="content">
              <h3>Dr. Larry A. Vea</h3>
              <ul class="social"></ul>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div class="single-team-member bg3">
            <img src="../../assets/images/team/team9.jpg" alt="member-image" />
            <div class="content">
              <h3>Dr. Melvin A. Ballera</h3>
              <ul class="social">
                <li>
                  <a
                    href="https://scholar.google.com/citations?user=bwjouuQAAAAJ&hl=en&oi=ao"
                    target="_blank"
                  >
                    <i class="flaticon-google"></i>
                  </a>
                </li>

                <li>
                  <a
                    href="https://ph.linkedin.com/in/melvin-ballera-29064a19b"
                    target="_blank"
                  >
                    <i class="flaticon-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div class="single-team-member bg4">
            <img src="../../assets/images/team/team10.jpg" alt="member-image" />
            <div class="content">
              <h3>Dr. Jheanel E. Estrada</h3>
              <ul class="social">
                <li>
                  <a
                    href="https://scholar.google.com/citations?user=zyTZLbcAAAAJ&hl=en&oi=ao"
                    target="_blank"
                  >
                    <i class="flaticon-google"></i>
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.linkedin.com/in/jheanel-estrada-8b2b37143"
                    target="_blank"
                  >
                    <i class="flaticon-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div class="single-team-member bg5">
            <img src="../../assets/images/team/team11.jpg" alt="member-image" />
            <div class="content">
              <h3>Dr. Enrico A. Chavez</h3>
              <ul class="social">
                <li>
                  <a
                    href="https://scholar.google.com/citations?user=LDY4a_EAAAAJ&hl=en&oi=ao"
                    target="_blank"
                  >
                    <i class="flaticon-google"></i>
                  </a>
                </li>

                <li>
                  <a
                    href="https://ph.linkedin.com/in/enrico-chavez-16150259"
                    target="_blank"
                  >
                    <i class="flaticon-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div class="single-team-member bg6">
            <img src="../../assets/images/team/team12.jpg" alt="member-image" />
            <div class="content">
              <h3>Dr. Alfio I. Regla</h3>
              <ul class="social">
                <li>
                  <a
                    href="https://scholar.google.com/citations?user=kiuVxtMAAAAJ&hl=en&oi=ao"
                    target="_blank"
                  >
                    <i class="flaticon-google"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div class="single-team-member bg7">
            <img src="../../assets/images/team/team13.jpg" alt="member-image" />
            <div class="content">
              <h3>Dr. Rufo I. Marasigan Jr.</h3>
              <ul class="social">
                <li>
                  <a
                    href="https://scholar.google.com/citations?user=jNVEek8AAAAJ&hl=en&oi=ao"
                    target="_blank"
                  >
                    <i class="flaticon-google"></i>
                  </a>
                </li>

                <li>
                  <a
                    href="https://ph.linkedin.com/in/rmarasiganjr"
                    target="_blank"
                  >
                    <i class="flaticon-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div class="single-team-member bg8">
            <img src="../../assets/images/team/team14.jpg" alt="member-image" />
            <div class="content">
              <h3>Dr. Jennifer B. Enriquez</h3>
              <ul class="social">
                <li>
                  <a
                    href="https://ph.linkedin.com/in/jennifer-enriquez-05650233"
                    target="_blank"
                  >
                    <i class="flaticon-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team",
};
</script>
