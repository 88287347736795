<template>
    <div class="services-area bg-fff4f8 pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">Services We Offer</span>
                <h2>We provide high-impact business services</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-career"></i>
                        </div>
                        <h3><router-link to="/services-details">Company & Business Setup</router-link ></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit. Dui id duis accumsan, eget aliquam varius. A sodales id nulla amet ultricies eget. At nulla mattis mattis.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-services-box active">
                        <div class="icon">
                            <i class="flaticon-growth"></i>
                        </div>
                        <h3><router-link to="/services-details">Strategic Consulting</router-link ></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit. Dui id duis accumsan, eget aliquam varius. A sodales id nulla amet ultricies eget. At nulla mattis mattis.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-return-of-investment"></i>
                        </div>
                        <h3><router-link to="/services-details">Investment Management</router-link ></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit. Dui id duis accumsan, eget aliquam varius. A sodales id nulla amet ultricies eget. At nulla mattis mattis.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-management-1"></i>
                        </div>
                        <h3><router-link to="/services-details">Company Management</router-link ></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit. Dui id duis accumsan, eget aliquam varius. A sodales id nulla amet ultricies eget. At nulla mattis mattis.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-money"></i>
                        </div>
                        <h3><router-link to="/services-details">Finance Consulting</router-link ></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit. Dui id duis accumsan, eget aliquam varius. A sodales id nulla amet ultricies eget. At nulla mattis mattis.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-human-resources"></i>
                        </div>
                        <h3><router-link to="/services-details">Human Resources</router-link ></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit. Dui id duis accumsan, eget aliquam varius. A sodales id nulla amet ultricies eget. At nulla mattis mattis.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>