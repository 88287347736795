<template>
  <div class="contact-info-area bg-f1f5fd">
    <div class="contact-area ptb-200">
      <div class="container">
        <div class="section-title style-two">
          <span class="sub-title">Contact us</span>
          <h2>Got Questions/Feedback? <br />Contact Us!</h2>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="contact-form">
              <form ref="form" @submit.prevent="sendEmail">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Your Name</label>
                      <input type="text" name="name" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Email Address</label>
                      <input type="email" name="email" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Subject</label>
                      <input
                        type="text"
                        name="msgSubject"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        name="phoneNumber"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label>Message...</label>
                      <textarea
                        name="message"
                        class="form-control"
                        cols="30"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <button type="submit" class="btn-style-one red-light-color">
                      Send Message <i class="ph-caret-right"></i>
                    </button>
                    <br /><br /><br />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div id="maps">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.0699567658576!2d120.99127401538027!3d14.595089503021088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c9f7aab4b6fd%3A0xba542e7ac1dc057!2sTechnological%20Institute%20of%20the%20Philippines%20-%20Arlegui%20Campus!5e0!3m2!1sen!2sph!4v1698160648423!5m2!1sen!2sph"
                width="600"
                height="450"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emailjs from "emailjs-com";

export default {
  name: "Contact",
  data() {
    return {
      name: "",
      email: "",
      msgSubject: "",
      phoneNumber: "",
      message: "",
    };
  },
  methods: {
    sendEmail(e) {
      e.preventDefault(); // Prevents the form from submitting the traditional way.
      emailjs
        .sendForm(
          "service_qzvz5ml",
          "template_kffoe5j",
          this.$refs.form,
          "rdWkKKBkV498X96Ys"
        )
        .then(
          (result) => {
            // Clearing the form fields
            this.$refs.form.reset();

            // Giving an alert to inform that the email has been sent successfully
            alert("Email sent successfully!");
          },
          (error) => {
            // Logging the error
            console.log("FAILED...", error.text);

            // Giving an alert to inform that there was an error in sending the email
            alert("Failed to send the email. Please try again later.");
          }
        );
    },
  },
};
</script>
