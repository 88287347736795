<template>
  <div>
    <NavbarStyleFive />
    <PageTitle
      subTitle="Blog right sidebar"
      heding="Our latest articles & resources"
    />
    <BlogRightSidebar />
    <Partner />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleFive from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import BlogRightSidebar from "../BlogRightSidebar/BlogRightSidebar";
import Partner from "../BlogRightSidebar/Partner";
import FooterStyleFive from "../Layouts/Footer";

export default {
  name: "BlogRightSidebarPage",
  components: {
    NavbarStyleFive,
    PageTitle,
    BlogRightSidebar,
    Partner,
    FooterStyleFive,
  },
};
</script>
