<template>
  <div class="courses-area ptb-100">
    <div class="container">
      <div class="row justify-content-center">
        <div
          class="col-lg-4 col-md-6 col-sm-6 mb-4"
          v-for="course in courses"
          :key="course.id"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div class="single-courses-box card p-4 h-100">
            <div class="content">
              <h3 class="nunito-font" v-html="course.title"></h3>
              <ul
                class="info d-flex align-items-center justify-content-between mb-3"
              >
                <li v-if="course.program">
                  <span>Degree: {{ course.program }}</span>
                </li>
                <li>
                  <span
                    v-if="course.status === 'accepting'"
                    class="badge status-badge accepting"
                    >Accepting</span
                  >
                  <span
                    v-else-if="course.status === 'freeze'"
                    class="badge status-badge freeze"
                    >Freeze</span
                  >
                  <span
                    v-else-if="course.status === 'disabled'"
                    class="badge status-badge disabled"
                    >Disabled</span
                  >
                </li>
              </ul>
              <ul
                class="box-footer d-flex justify-content-between align-items-center mb-3"
              >
                <li>
                  <i class="fas fa-book me-2"></i>
                  {{ course.units }} Units
                </li>
                <li>
                  <i class="fas fa-clock me-2"></i> {{ course.minYears }} Min
                  Years
                </li>
              </ul>
              <div class="button-group mt-auto d-flex justify-content-between">
                <router-link
                  :to="'/program-details/' + course.id"
                  class="btn btn-outline-primary btn-oval"
                  >Read More</router-link
                >
                <router-link
                  :to="'/enroll/' + course.id"
                  class="btn btn-gold btn-oval"
                  >Enroll Now</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Courses",
  data() {
    return {
      courses: [
        {
          id: "dit",
          title: "Doctor in Information Technology<br>(DIT)",
          program: "Doctorate",
          units: 36,
          minYears: 3,
          status: "accepting",
        },
        {
          id: "phdcs",
          title: "Doctor of Philosophy in Computer Science<br>(PHDCS)",
          program: "Doctorate",
          units: 36,
          minYears: 3,
          status: "accepting",
        },
        {
          id: "mit",
          title: "Master in Information Technology<br>(MIT)",
          program: "Masteral",
          units: 30,
          minYears: 2,
          status: "accepting",
        },
        {
          id: "mengche",
          title: "Master of Engineering in Chemical Engineering<br>(MENG-CHE)",
          program: "Masteral",
          units: 30,
          minYears: 2,
          status: "accepting",
        },
        {
          id: "mengcpe",
          title: "Master of Engineering in Computer Engineering<br>(MENG-CpE)",
          program: "Masteral",
          units: 30,
          minYears: 2,
          status: "accepting",
        },
        {
          id: "mengee",
          title: "Master of Engineering in Electrical Engineering<br>(MENG-EE)",
          program: "Masteral",
          units: 30,
          minYears: 2,
          status: "accepting",
        },
        {
          id: "mengme",
          title: "Master of Engineering in Mechanical Engineering<br>(MENG-ME)",
          program: "Masteral",
          units: 30,
          minYears: 2,
          status: "accepting",
        },
        {
          id: "mscs",
          title: "Master of Science in Computer Science<br>(MSCS)",
          program: "Masteral",
          units: 30,
          minYears: 2,
          status: "accepting",
        },
        {
          id: "mis",
          title: "Master in Information Systems<br>(MIS)",
          program: "Masteral",
          units: 30,
          minYears: 1,
          status: "freeze",
        },
        {
          id: "psmds",
          title: "Professional Science Master's in Data Science<br>(PSMDS)",
          program: "Masteral",
          units: 30,
          minYears: 1,
          status: "accepting",
        },
        {
          id: "psmem",
          title:
            "Professional Science Master’s in Engineering Management<br>(PSMEM)",
          program: "Masteral",
          units: 30,
          minYears: 1,
          status: "accepting",
        },
      ],
    };
  },
};
</script>

<style scoped>
.card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.card:hover {
  transform: translateY(-5px);
}
.badge {
  font-size: 0.9rem;
  padding: 0.4em 0.6em;
  border-radius: 5px;
}

.status-badge.accepting {
  background-color: #28a745; /* Green for accepting */
  color: white;
}
.status-badge.freeze {
  background-color: #dc3545; /* Red for freeze */
  color: white;
}
.status-badge.disabled {
  background-color: #6c757d; /* Gray for disabled */
  color: white;
}

.box-footer {
  font-size: 0.85rem;
  color: #6c757d;
}
.button-group {
  margin-top: auto;
}

.btn-oval {
  border-radius: 50px; /* Oval button style */
  padding: 0.5rem 1.5rem; /* Adjusted padding for a modern look */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-primary {
  background-color: #007bff;
  border: none;
}
.btn-primary:hover {
  background-color: #0056b3;
}
.btn-outline-primary {
  border-color: #007bff;
  color: #007bff;
}
.btn-outline-primary:hover {
  background-color: #007bff;
  color: #ffffff;
}
.btn-gold {
  background-color: #f0c14b; /* Light gold yellow color */
  color: #fff;
  border: none;
}
.btn-gold:hover {
  background-color: #d4a017; /* Darker shade of gold for hover */
}
</style>

<!-- Add Font Awesome CDN in your index.html file -->
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>
