<template>
  <div>
    <Navbar />
    <PageTitle subTitle="Authentication" heding="Howdy! Welcome Back!" />
    <Authentication />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import Authentication from "../Authentication/Authentication";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "AuthenticationPage",
  components: {
    Navbar,
    PageTitle,
    Authentication,
    FooterStyleTwo,
  },
};
</script>
