<template>
  <div class="thank-you-area">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="thank-you-content">
            <img src="../../assets/images/thank-you.png" alt="thank-you" />
            <h3>Thank You!</h3>
            <p>Check your inbox for the order details!</p>
            <router-link to="/" class="btn-style-one red-light-color"
              >Back to Home <i class="ph-caret-right"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYouPage",
};
</script>
