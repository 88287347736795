<template>
  <div>
    <Navbar />

    <Contact />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import Contact from "../Contact/Contact";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "ContactPage",
  components: {
    Navbar,
    PageTitle,
    Contact,
    FooterStyleTwo,
  },
};
</script>
