<template>
  <div>
    <NavbarStyle />
    <MainBanner />
    <FooterStyle />
  </div>
</template>

<script>
import NavbarStyle from "../Layouts/Navbar";
import MainBanner from "../DistanceLearning/MainBanner";
import Features from "../DistanceLearning/Features";
import DevelopSkill from "../DistanceLearning/DevelopSkill";
import Courses from "../DistanceLearning/Courses";
import RegistrationProcess from "../DistanceLearning/RegistrationProcess";
import Testimonials from "../DistanceLearning/Testimonials";
import Funfacts from "../DistanceLearning/Funfacts";
import Blog from "../DistanceLearning/Blog";
import Partner from "../DistanceLearning/Partner";
import AppDownload from "../DistanceLearning/AppDownload";
import FooterStyle from "../Layouts/Footer";

export default {
  name: "DistanceLearningPage",
  components: {
    NavbarStyle,
    MainBanner,
    Features,
    DevelopSkill,
    Courses,
    RegistrationProcess,
    Testimonials,
    Funfacts,
    Blog,
    Partner,
    AppDownload,
    FooterStyle,
  },
};
</script>
