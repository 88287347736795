<template>
  <div>
    <NavbarStyleEight />
    <PageTitle
      subTitle="Our Services"
      heding="Our work is delivered by the best team in the world"
    />
    <OurGoal />
    <WhatWeDo />
    <WhatWeOffer />
    <Testimonials />
    <FooterStyleEight />
  </div>
</template>

<script>
import NavbarStyleEight from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import OurGoal from "../ServicesFour/OurGoal";
import WhatWeDo from "../ServicesFour/WhatWeDo";
import WhatWeOffer from "../ServicesFour/WhatWeOffer";
import Testimonials from "../ServicesFour/Testimonials";
import FooterStyleEight from "../Layouts/Footer";

export default {
  name: "ServicesFourPage",
  components: {
    NavbarStyleEight,
    PageTitle,
    OurGoal,
    WhatWeDo,
    WhatWeOffer,
    Testimonials,
    FooterStyleEight,
  },
};
</script>
