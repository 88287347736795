<template>
  <div>
    <Navbar />
    <PageTitle
      subTitle="Our Creative Team"
      heding="Our team believes you deserve only the best"
    />
    <Team />
    <FooterStyleTwo />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import Team from "../Team/Team";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "TeamPage",
  components: {
    Navbar,
    PageTitle,
    Team,
    FooterStyleTwo,
  },
};
</script>
