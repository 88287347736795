<template>
  <div>
    <NavbarStyleThree />
    <MainBanner />
    <Partner />
    <ReceivePayments />
    <Features />
    <Testimonials />
    <Pricing />
    <Faq />
    <DownloadApp />
    <FooterStyleThree />
  </div>
</template>

<script>
import NavbarStyleThree from "../Layouts/Navbar";
import MainBanner from "../App/MainBanner";
import Partner from "../App/Partner";
import ReceivePayments from "../App/ReceivePayments";
import Features from "../App/Features";
import Testimonials from "../App/Testimonials";
import Pricing from "../App/Pricing";
import Faq from "../App/Faq";
import DownloadApp from "../App/DownloadApp";
import FooterStyleThree from "../Layouts/Footer";

export default {
  name: "AppPage",
  components: {
    NavbarStyleThree,
    MainBanner,
    Partner,
    ReceivePayments,
    Features,
    Testimonials,
    Pricing,
    Faq,
    DownloadApp,
    FooterStyleThree,
  },
};
</script>
