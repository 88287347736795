<template>
    <div class="get-started-area">
        <div class="container">
            <div class="get-started-inner-area" data-aos="fade-up" data-aos-duration="1200">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-12">
                        <h2>We’re delivering the best customer experience</h2>
                    </div>
                    <div class="col-lg-5 col-md-12 text-end">
                        <router-link to="/contact" class="btn-style-one red-light-color">
                            Let’s Get Started 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GetStarted'
}
</script>