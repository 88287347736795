<template>
  <div>
    <Navbar />
    <PageTitle subTitle="About Us" heding="Hello World! This is Vumy!" />
    <About />
    <WhyChooseUs />
    <OurWorkingProcess />
    <Funfacts />
    <Team />
    <Testimonials />
    <Partner />
    <GetStarted />
    <FooterStyleTwo class="bg-f9f9f9" />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import About from "../AboutOne/About";
import WhyChooseUs from "../AboutOne/WhyChooseUs";
import OurWorkingProcess from "../AboutOne/OurWorkingProcess";
import Funfacts from "../AboutOne/Funfacts";
import Team from "../AboutOne/Team";
import Testimonials from "../Common/Testimonials";
import Partner from "../AboutOne/Partner";
import GetStarted from "../AboutOne/GetStarted";
import FooterStyleTwo from "../Layouts/Footer";

export default {
  name: "AboutOnePage",
  components: {
    Navbar,
    PageTitle,
    About,
    WhyChooseUs,
    OurWorkingProcess,
    Funfacts,
    Team,
    Testimonials,
    Partner,
    GetStarted,
    FooterStyleTwo,
  },
};
</script>
